<template>

<!-- 用户管理 教师管理页面 -->
  <div class="container">
    <div class="header-container">
      <!-- <div class="seek-container">
        <div class="seek-section">
          <i class="el-icon-search"></i>
          <el-input placeholder="请输入昵称"></el-input>
        </div>
        <el-button class="seek-button">
          搜索
        </el-button>
      </div> -->
      <div class="add-container">
        <router-link :to="{name: 'managementSchool'}">
          <el-button class="addUser-button">
            返回
          </el-button>
        </router-link>
        <el-divider direction="vertical"></el-divider>
        <router-link :to="{name: 'managementTeacherAdd', query: {schoolId: show.schoolId}}">
          <el-button class="addUser-button">
            新增
          </el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="teacherList"
        stripe
        style="width: 100%">
        <!-- <el-table-column
          prop="gradeName"
          label="年级"
          empty-text="-">
        </el-table-column>
        <el-table-column
          prop="className"
          label="班级">
        </el-table-column> -->
        <el-table-column
          label="序号"
          width="80">
          <template slot-scope="scope">
            <p class="index" style="width: 100%; text-align: left;">
              {{scope.$index + 1}}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          prop="teacherName"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="phone"
          label="手机号">
        </el-table-column>
        <el-table-column label="设置">
          <template slot-scope="scope">
            <router-link :to="{name: 'managementTeacherEdit', params: {item: scope.row}, query: { schoolId: show.schoolId }}">
              <el-button
              size="mini"
              type="text"
              >编辑</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <el-button
             @click="showDel(scope.row.teacherId)"
              size="mini"
              type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <pagination :current-page="show.startPage" :total="show.totalPage"  @currentChange="refreshGetData" @sizeChange="sizeChange"></pagination>
    </div>
  </div>
</template>
<script>
import AdministratorService from '@/globals/service/management/Administrator.js'

export default {
  data () {
    return {
      teacherList: [],
      show: {
        pageSize: 8,
        schoolId: null,
        startPage: 1,
        totalPage: 1
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    showDel (id) {
      this.$confirm('此操作将永久删除该老师, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    del (id) {
      AdministratorService.AdministratorDeleteTeacher({ id }).then(res => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.show.startPage = 1
        this.getData()
      })
    },
    refreshGetData (index) {
      this.show.startPage = index
      this.getData()
    },
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    getData () {
      this.show.schoolId = this.$route.query.schoolId
      const data = this.show
      AdministratorService.AdministratorGetTeacherList(data).then(res => {
        this.teacherList = res.data.teacherList
        this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
